<template>
    <div class="course-modal" @click.self="close">
        <div class="course-modal-window" v-if="courses">
            <header>
                <BackButton class="back" :class="{ visible: chosen_target_language || languageNotListedVisible }" @click="backAction" />
                <h2 class="title">{{ title }}</h2>
                <CloseButton @click="close" class="close" v-if="!courseNeedsToBeSet" />
            </header>
            <template v-if="languageNotListedVisible">
                <language-not-listed
                    :selected_target_language="{ code: this.chosen_target_language }"
                    :show-title="false"
                    @back="hideLangNotListed"
                />
            </template>
            <template v-else>
                <section class="courses-list" ref="list">
                    <template v-if="this.chosen_target_language">
                        <course-list-item :show-subtitle="false" :show-meta="false" v-bind:course="chosen_course" />
                        <template v-if="source_languages_filtered && source_languages_filtered.length > 0">
                            <h3 v-html="this.$i18n('hub_language_selection_source_language_title')"/>
                            <template v-for="course in source_languages_filtered">
                                <course-list-item :show-subtitle="false" :show-source="true" v-bind:course="course" @click.native="addCourse(course)" />
                            </template>
                            <course-list-item :show-not-listed="true" @click.native="showLangNotListed" />
                        </template>
                    </template>
                    <template v-else>
                        <template v-if="courses && courses.coursesEnrolled && courses.coursesEnrolled.length > 0">
                            <h3 v-html="this.$i18n('hub_language_selection_currently_active_title')"/>
                            <template v-for="course in enrolledCourses">
                                <course-list-item v-bind:course="course" v-on:select-course="addCourse" :show-more-dropdown="true" />
                            </template>
                        </template>
                        <template v-if="target_languages && target_languages.length > 0">
                            <h3 v-html="this.$i18n('hub_language_selection_available_languages_title')"/>
                            <template v-for="language in target_languages">
                                <course-list-item :show-subtitle="false" v-bind:course="language" @click.native="languageChosen(language)" />
                            </template>
                            <course-list-item :show-not-listed="true" @click.native="showLangNotListed" />
                        </template>
                    </template>
                </section>
            </template>
        </div>
    </div>
</template>

<script>
import CloseButton from 'Images/close-24x24.svg';
import BackButton from 'Images/back-arrow-20x20.svg';
import VButton from 'ViewComponent/v-button.vue';
import CourseListItem from "./course-list-item.vue";
import UserManager from "Modules/usermanager.js";
import { EventBus } from 'Util/vue-event-bus.js';
import { NAME as USER_PARAMETER } from "Modules/user.parameters.js";
import _, { sortBy, cloneDeep } from "lodash";
import i18nUtils from "Util/i18n.js";
import CourseIcon from "ViewComponent/course-icon.vue";
import LanguageNotListed from "ViewComponent/language-not-listed.vue";

export default {
    name: 'course-modal',
    props: {
        courses: {
            type: Object
        },
    },
    components: {
        LanguageNotListed,
        CourseIcon,
        CourseListItem,
        VButton,
        CloseButton,
        BackButton
    },
    data: function () {
        return {
            current_course_uuid: null,
            chosen_target_language: null,
            chosen_course: null,
            languageNotListedVisible: false
        }
    },
    computed: {
        title () {
            if (this.languageNotListedVisible) {
                return i18nUtils.prop('register_language_not_listed_title');
            } else {
                return i18nUtils.prop('hub_language_selection_title');
            }
        },
        courseNeedsToBeSet() {
            const hasActiveCourse = !!(this.courses && this.courses.activeCourse);
            if (hasActiveCourse) {
                return false;
            } else {
                return !this.current_course_uuid || !this.courses || !this.courses.coursesAvailableActiveSource && !this.courses.coursesAvailableOtherSource && this.courses.coursesAvailableAll && this.courses.coursesAvailableAll.length > 0;
            }
        },
        target_languages () {
            if (this.courses && this.courses.coursesAvailableAll) {
                let languagesObject = _(this.courses.coursesAvailableAll)
                    .chain()
                    .filter(course => !course.hidden)
                    .reduce((result, c, i) => {
                        const { target_language } = c;
                        if (target_language === 'en-US' || target_language === 'en-GB') {
                            c.code = 'en';
                            result['en'] = c
                        } else if (!result[target_language]) {
                            c.code = target_language;
                            result[target_language] = c;
                        }
                        return result;
                    }, {})
                    .value();

                let _languages = Object.values(languagesObject);

                if (_languages) {
                    _languages.map(language => {
                        language.name = i18nUtils.prop(language.code, null, null,'language_name');
                        return language;
                    });

                    return sortBy(_languages, 'name');
                } else {
                    return [];
                }
            }
        },
        source_languages () {
            if (this.courses && this.courses.coursesAvailableAll) {
                let languagesObject = _(this.courses.coursesAvailableAll)
                    .chain()
                    .filter(course => !course.hidden)
                    .reduce((result, c, i) => {
                        const { source_language } = c;
                        if (source_language === 'en-US' || source_language === 'en-GB') {
                            c.code = 'en';
                            result['en'] = c
                        } else if (!result[source_language]) {
                            c.code = source_language;
                            result[source_language] = c;
                        }
                        return result;
                    }, {})
                    .value();

                let _languages = Object.values(languagesObject);

                if (_languages) {
                    _languages.map(language => {
                        language.name = i18nUtils.prop(language.code, null, null,'language_name');
                        return language;
                    });

                    return sortBy(_languages, 'name');
                } else {
                    return [];
                }
            }
        },
        source_languages_filtered() {
            if (this.courses.allCourses && this.chosen_target_language) {
                let langArray = [this.chosen_target_language]
                if (this.chosen_target_language === 'en') {
                    langArray = ['en-US', 'en-GB']
                }

                let _languages = cloneDeep(this.courses.allCourses)
                    .filter(lang => !lang.hidden)
                    .filter(lang => langArray.includes(lang.target_language));
                _languages.map(lang => {
                    lang.name = i18nUtils.prop(lang.source_language, null, null,'language_name')
                    return lang;
                });

                return sortBy(_languages, 'name');
            } else {
                return [];
            }
        },
        enrolledCourses() {
            if (this.courses && this.courses.coursesEnrolled) {
                let coursesEnrolled = cloneDeep(this.courses.coursesEnrolled);
                let activeCourse = cloneDeep(this.courses.activeCourse);
                activeCourse.action = 'learning';

                let enrolledCoursesFiltered = sortBy(coursesEnrolled.filter(course => course.action !== 'learning'), 'name');
                enrolledCoursesFiltered.unshift(activeCourse); // unshift activeCourse as a first element

                return enrolledCoursesFiltered;
            }
        }
    },
    methods: {
        close() {
            if (!this.courseNeedsToBeSet) {
                this.$emit('close-course-modal');
            }
        },
        keyDown(event) {
            if (event && event.keyCode === 27) { // close on ESC key
                this.close()
            }
        },
        addCourse(course) {
            EventBus.$emit('add-course', course);
            this.close();
        },
        languageChosen(course) {
            this.resetCoursesListScroll();
            this.chosen_course = course;
            this.chosen_target_language = (course.code) ? course.code : course.target_language;
        },
        setCurrentCourseUuid() {
            this.current_course_uuid = UserManager.instance.getUser().getParameters().getParameter(USER_PARAMETER.CURRENT_COURSE_UUID);
        },
        backAction() {
            this.chosen_course = null;
            this.chosen_target_language = null;
            this.hideLangNotListed();
        },
        resetCoursesListScroll() {
            this.$refs.list.scrollTop = 0;
        },
        showLangNotListed() {
            this.languageNotListedVisible = true;
        },
        hideLangNotListed() {
            this.languageNotListedVisible = false;
        }
    },
    mounted() {
        document.addEventListener('keydown', this.keyDown);
        document.body.classList.add('modal-open');
        this.setCurrentCourseUuid();
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.keyDown);
        document.body.classList.remove('modal-open');
        this.chosen_course = null;
        this.chosen_target_language = null;
    }
};
</script>

<style lang="scss">
@import "~Styles/_colors.scss";
@import "~Styles/_mixins.scss";

div.course-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(43,81,142,0.80);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > div.course-modal-window {
        position: relative;
        @include elevated;
        background-color: #fff;
        border-radius: pxToRem(12);
        min-width: pxToRem(500);
        min-height: pxToRem(400);
        display: flex;
        flex-direction: column;
        max-width: 40vw;
        box-sizing: border-box;
        padding: pxToRem(24);

        @include respond-to('small') {
            min-width: 100vw;
            min-height: unset;
            border-radius: unset;
        }

        > header {
            display: flex;
            flex-direction: row;
            gap: 1rem;
            align-items: center;
            > svg.back {
                visibility: hidden;
                pointer-events: none;
                &.visible {
                    visibility: visible;
                    pointer-events: all;
                }
                body[data-interface-language="ar"] & {
                    transform: rotate(180deg);
                }
            }
            > h2.title {
                text-align: center;
                flex: 1;
            }
            > svg.back,
            > svg.close {
                cursor: pointer;
                transition: 100ms ease-in-out;
                &:hover {
                    transform: scale(1.2);
                }
            }
            > svg.close {
                margin-left: auto;
            }
        }

        > section.courses-list {
            display: flex;
            flex-direction: column;
            gap: .25rem;
            margin-top: 1rem;
            max-height: pxToRem(500);
            overflow-y: auto;
            padding-right: .5rem;
            @include minimalistScrollbar;
            body[data-interface-language="ar"] & {
                padding-right: unset;
                padding-left: .5rem;
            }
            > h3 {
                margin: 1rem .5rem .5rem;
                &:first-child {
                    margin-top: .5rem;
                }
            }
        }

        > section.language-not-listed {
            margin-top: 1rem;
        }
    }
}
</style>
