export default {
    "ar": "messages_ar.365419dd7bccaf9fe3f2204a5bf08b4e.json",
    "de": "messages_de.7cbcaa66151bff1e3558bd23c9622b5b.json",
    "en-GB": "messages_en-GB.03ffd3ba2d923b9d0b506d1ba9b0c40f.json",
    "en-US": "messages_en-US.3f2e7a2e02c695cf495abd054de309f0.json",
    "en": "messages_en.a124c7e96e2eb86fa1e79aaefecb2e85.json",
    "es": "messages_es.24879236a0c039514f48e505cf839a6a.json",
    "et": "messages_et.111e4c23e0e7961aeadf0884281d5ce9.json",
    "fr-CA": "messages_fr-CA.51f079b8c1cc0861f7ee51c0f716bc68.json",
    "fr": "messages_fr.00433a0b0b6456d79ebd03050ff23900.json",
    "hi": "messages_hi.3777feab22e94b3e161498cb29657d89.json",
    "it": "messages_it.83eb256f4a10d506839bdb287e0e467c.json",
    "ja": "messages_ja.a55caaa596bae40ca8dbbcfa2b66db29.json",
    "nl": "messages_nl.e7025cb06452c63e7813da4b7e0a0591.json",
    "pl": "messages_pl.ee577dbad20c49cb06535200f05e4a24.json",
    "pt-BR": "messages_pt-BR.e83f4aef70f6b312321aafcb583e9b11.json",
    "pt": "messages_pt.8ee872ba7492cee1ba14fd322458988e.json",
    "ru": "messages_ru.82da620f0801aa01382ee41c57373b96.json",
    "th": "messages_th.ba707b38d39a81808411e34b87b63761.json",
    "tr": "messages_tr.891085c4788f396aab7edc73b11b77cd.json",
    "uk": "messages_uk.18a42afde903eefb0a55379a21d49e72.json",
    "vi": "messages_vi.04ef85a6cbc20eef74be6933f0fc25d4.json",
    "zh-Hans": "messages_zh-Hans.b89f4c2b7084d94eb80d4cc43959ec45.json",
    "zh-Hant": "messages_zh-Hant.eb57fb081d240c1c42924c63eff80946.json"
};